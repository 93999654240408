import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { createStripeKey } from "../../shared/app.constants";

@Component({
  selector: 'app-my-bid',
  templateUrl: './my-bid.component.html',
  styleUrls: ['./my-bid.component.css']
})
export class MyBidComponent implements OnInit {
  ChartonList = []
  bidForm: FormGroup;
  constructor(
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    public formBuilder: FormBuilder,
    private router: Router,
  ) {
    this.bidForm = this.formBuilder.group({
      BidID: ['', Validators.required],
      StripeToken: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.GetChartonList();
    this.loadStripe();
  }

  loadStripe() {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }

  GetChartonList() {
    this.commonService.getData('buy/get-my-bid-list').subscribe(
      response => {
        if (response.status) {
          this.ChartonList = response.data.charton_data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

  pay_now(bid_id, amount) {
    let that = this
    var handler = (<any>window).StripeCheckout.configure({
      key: createStripeKey(),
      locale: 'auto',
      token: function (token: any) {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        // console.log(token)
        // alert('Token Created!!');
        that.bidForm.controls.StripeToken.setValue(token.id)
        that.bidForm.controls.BidID.setValue(bid_id)

        that.commonService.addData('buy/bid-payment', that.bidForm.value).subscribe(
          response => {
            if (response.status) {
              that.errorLogService.handleSuccess(response.message);
              that.router.navigate(['/thank-you/' + response.data.transaction_id]);
            } else {
              that.errorLogService.handleError(response.message)
            }
          },
          error => that.errorLogService.handleError(error)
        );
      }
    });
    // var amount = this.buyForm.controls['PayableAmount'].value
    handler.open({
      name: 'Carbonface',
      // email: 'test1@test.com',
      description: 'Buy Charton',
      amount: amount * 100
    });

    // this.commonService.addData('buy/buy-charton', this.buyForm.value).subscribe(
    //     response => {
    //       if (response.status) {
    //         this.errorLogService.handleSuccess(response.message);
    //         this.router.navigate(['/thank-you/' + response.data.transaction_id]);
    //       } else {
    //         this.errorLogService.handleError(response.message)
    //       }
    //     },
    //     error => this.errorLogService.handleError(error)
    //   );
  }
}
