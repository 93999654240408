import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material';
import { UserService } from 'src/app/services/user.service';
import { appURL } from "../../../shared/app.constants";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from "@angular/router";
import { Location } from "@angular/common";
import { ScrollTopService } from 'src/app/services/scrolltop.service';
import { CommonService } from '../../../services/common.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ErrorLogService } from '../../../services/error-log.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @ViewChild('LoginModal')
  private LoginModal: TemplateRef<any>;
  IsLogin = false;
  route: string;
  username: string;
  Name: string;
  Email: string;
  SiteSetting: any;
  SiteName: any;
  LogoPreview: string;
  ProfileImage: string = appURL.ProfilePath + 'no_profile.png';
  SocialSite: string = appURL.SocialSite;
  NotificationCount: number = 0;
  NotificationList = [];
  header_class = ''
  LoginModelRef: BsModalRef;
  loginForm: FormGroup;
  

  constructor(
    private scrollTopService: ScrollTopService,
    public userService: UserService,
    public dialog: MatDialog,
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    location: Location,
    private router: Router,
    private modalService: BsModalService,
    private errorLogService: ErrorLogService,
  ) {
    router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
        this.header_class = ''
      } else {
        this.header_class = 'home_header'
        this.route = "Home";
      }
    });
    this.loginForm = this.formBuilder.group({
      Email: ['', Validators.required],
      Password: ['', Validators.required],
    });
  }
  ngOnInit() {
    this.scrollTopService.setScrollTop();
    this.IsLogin = this.userService.isLoggedIn
    this.commonService.invokeEvent.subscribe(value => {
      if (value == 'SiteDetail') {
        this.getSiteDetail();
      }
      this.IsLogin = this.userService.isLoggedIn
    })
    this.getSiteDetail();
  }

  onLoginClick(redirect='dashboard') {
    localStorage.removeItem('buy-listing-id');
    localStorage.setItem('redirect_after_login',redirect);
    this.LoginModelRef = this.modalService.show(this.LoginModal, { class: 'modal-md' });
  }
  onLogout() {
    this.userService.logout()
    this.commonService.ChangeUpdate('Logout');
    if (this.route == 'Home') {
      this.router.navigate(['/profile'])
    }
  }
  loadTranslate() {
    if (!window.document.getElementById('translate-script')) {
      var s = window.document.createElement("script");
      s.id = "translate-script";
      s.type = "text/javascript";
      s.src = "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      window.document.body.appendChild(s);
    }
  }
  getSiteDetail() {
    this.commonService.getData('auth/setting').subscribe(
      response => {
        if (response.status) {
          this.SiteSetting = response.data;
          this.SiteSetting.forEach(element => {
            if (element.Key == 'Logo') {
              this.LogoPreview = appURL.logoImagesPath + element.Value;
            }
            if (element.Key == 'SiteName') {
              this.SiteName = element.Value;
            }
          });
        }
      },
    );
  }

  onSubmitLogin() {
    this.loginForm.controls['Email'].markAsTouched()
    this.loginForm.controls['Password'].markAsTouched()
    if (this.loginForm.valid) {
      this.userService.login(this.loginForm.value)
    }
  }

  open_buy_listing() {
    // localStorage.setItem('buy-listing-id', '0');
    this.router.navigate(['/buy-listing'])
  }
}