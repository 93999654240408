import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-biochar',
  templateUrl: './biochar.component.html',
  styleUrls: ['./biochar.component.css']
})
export class BiocharComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
