import { Component, OnInit } from '@angular/core';
import { ScrollTopService } from '../../services/scrolltop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';
import { createStripeKey } from "../../shared/app.constants";
import { ConfirmationDialogService } from '../../services/confirmation-dialog.service';
import { ValidationService } from '../../services/validation.service';

@Component({
  selector: 'app-buy-details',
  templateUrl: './buy-details.component.html',
  styleUrls: ['./buy-details.component.css']
})
export class BuyDetailsComponent implements OnInit {
  ChartonList = []
  ChartonData = {
    'UserPicture': '',
    'CountryName': '',
    'CategoryName': '',
    'UserFirstName': '',
    'UserLastName': '',
    'OriginCountryName': '',
    'OriginCategoryName': '',
    'TotalToken': '',
    'OriginUserFirstName': '',
    'OriginUserLastName': '',
    'BasePrice': '',
    'AvailableToken': '',
    'UserBiography': '',
  }
  CurrentUserID: any = localStorage.getItem('UserID');
  CurrentUserEmail: any = localStorage.getItem('UserEmail');
  ID: any = 0;
  BuyerUserID: any = 0;
  buyForm: FormGroup;
  filterForm: FormGroup;
  DisplayPayableAmount: any = 0;
  AvailableToken: number;
  

  private activatedRoute: ActivatedRoute;
  constructor(
    private scrollTopService: ScrollTopService,
    activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    private router: Router,
    public confirmationDialogService: ConfirmationDialogService
  ) {
    this.activatedRoute = activatedRoute;
    this.ID = this.activatedRoute.snapshot.paramMap.get("ID");
    this.buyForm = this.formBuilder.group({
      HeaderID: [this.ID, Validators.required],
      PayableAmount: ['', Validators.required],
      BuyQuantity: ['', [Validators.required, ValidationService.decimalValidation]],
      StripeToken: [''],
      BasePrice: [0],
    });

    this.filterForm = this.formBuilder.group({
      ChartonType: ['unmined', Validators.required],
      HeaderID: [this.ID, Validators.required],
    });

  }

  ngOnInit() {
    // console.log(this.ID)
    this.scrollTopService.setScrollTop();
    this.getChartonDetails();
    this.loadStripe();
    // this.getChartonVideoList(0);
    // this.GetChartonList();
  }

  getChartonVideoList(BuyerUserID : any) {
    const formData = new FormData();
    formData.append('UserID', BuyerUserID);
    this.commonService.postData('profile/unmined_latest', formData).subscribe(
      response => {
        if (response.status) {
          this.ChartonList = response.data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }
  loadStripe() {
    if (!window.document.getElementById('stripe-script')) {
      var s = window.document.createElement("script");
      s.id = "stripe-script";
      s.type = "text/javascript";
      s.src = "https://checkout.stripe.com/checkout.js";
      window.document.body.appendChild(s);
    }
  }

  getChartonDetails() {
    this.commonService.getData('buy/get-charton-details/' + this.ID).subscribe(
      response => {
        if (response.status) {
          this.ChartonData = response.data
          this.BuyerUserID = response.data.UserID
          console.log(response.data.UserID,'response.data.UserID')
          this.getChartonVideoList(response.data.UserID)
          this.AvailableToken = Number(this.ChartonData['AvailableToken'])
          this.buyForm.controls.BasePrice.setValue(this.ChartonData['BasePrice'])
          // this.AvailableToken = (this.AvailableToken / 100).toFixed(2)
          // this.buyForm.controls.BuyQuantity.setValue((AvailableToken / 100).toFixed(2))
          this.PayableAmount()
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );

  }

  GetChartonList() {
    this.commonService.postData('buy/get-buy-charton', this.filterForm.value).subscribe(
      response => {
        if (response.status) {
          this.ChartonList = response.data.charton_data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );

  }

  PayableAmount() {
    let TotalToken = Number(this.ChartonData['TotalToken'])
    let TotalPrice = Number(this.ChartonData['BasePrice'])
    // let PerTokenPrice = TotalPrice / TotalToken
    let PerTokenPrice = TotalPrice
    // let BuyQuantityToken = this.buyForm.controls['BuyQuantity'].value * 100
    let BuyQuantityToken = this.buyForm.controls['BuyQuantity'].value
    // this.DisplayPayableAmount = (BuyQuantityToken * PerTokenPrice).toFixed(2);
    this.DisplayPayableAmount = (BuyQuantityToken * TotalPrice).toFixed(2);
    this.buyForm.controls.PayableAmount.setValue(this.DisplayPayableAmount)
  }

  onSubmit() {
    this.buyForm.controls['BuyQuantity'].markAsTouched()
    // console.log(this.buyForm.value)
    if (this.buyForm.valid) {

      var AvailableToken = Number(this.ChartonData.AvailableToken)
      var BuyQuantityToken = Number(this.buyForm.controls['BuyQuantity'].value) * 100
      if (BuyQuantityToken <= AvailableToken) {

        var html = '<div class="thanks_details thanks_details_popup pl-3">'
        html += '<div class="table-responsive">'
        html += '<table class="table" style="text-align: center;">'
        html += '<tbody>'
        html += '<tr>'
        html += '<td>CHARTON Quantity</td>'
        html += '<td><label>'+ this.buyForm.controls['BuyQuantity'].value +'</label></td>'
        html += '</tr>'
        html += '<tr>'
        html += '<td>Payable Amount</td>'
        html += '<td><label>$ '+this.buyForm.controls['PayableAmount'].value+'</label></td>'
        html += '</tr>'
        html += '</tbody>'
        html += '</table>'
        html += '</div>'
        html += '</div>'

        this.confirmationDialogService.Paymentconfirm('Confirm', 'Are you sure to continue purchase the CHARTON.', html, (response: boolean) => {
          if (response) {

            let that = this
            var handler = (<any>window).StripeCheckout.configure({
              key: createStripeKey(),
              locale: 'auto',
              token: function (token: any) {
                // You can access the token ID with `token.id`.
                // Get the token ID to your server-side code for use.
                // console.log(token)
                // alert('Token Created!!');
                that.buyForm.controls.StripeToken.setValue(token.id)
                that.commonService.addData('buy/buy-charton', that.buyForm.value).subscribe(
                  response => {
                    if (response.status) {
                      that.errorLogService.handleSuccess(response.message);
                      that.router.navigate(['/thank-you/' + response.data.transaction_id]);
                    } else {
                      that.errorLogService.handleError(response.message)
                    }
                  },
                  error => that.errorLogService.handleError(error)
                );
              }
            });
            var amount = this.buyForm.controls['PayableAmount'].value
            handler.open({
              name: 'Carbonface',
              email: this.CurrentUserEmail,
              description: 'Buy Charton',
              amount: amount * 100
            });
          }
        });

      } else {
        this.errorLogService.handleError('CHARTON should not be greater then avaialble qunatity.')
      }
    }

    // this.commonService.addData('buy/buy-charton', this.buyForm.value).subscribe(
    //     response => {
    //       if (response.status) {
    //         this.errorLogService.handleSuccess(response.message);
    //         this.router.navigate(['/thank-you/' + response.data.transaction_id]);
    //       } else {
    //         this.errorLogService.handleError(response.message)
    //       }
    //     },
    //     error => this.errorLogService.handleError(error)
    //   );
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
