import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';

@Component({
  selector: 'app-bid-request',
  templateUrl: './bid-request.component.html',
  styleUrls: ['./bid-request.component.css']
})
export class BidRequestComponent implements OnInit {
  ChartonList = []
  constructor(
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
  ) { }

  ngOnInit() {
    this.GetChartonList();
  }

  GetChartonList() {
    this.commonService.getData('buy/get-bid-request-list').subscribe(
      response => {
        if (response.status) {
          this.ChartonList = response.data.charton_data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

  toggle_bid(div_id) {
    $("#"+div_id).toggle();
  }
  accept_bid(bid_id, charton_id) {
    // console.log(bid_id)
    // this.commonService.postData('buy/bid-accept', this.sellForm.value).subscribe(
      this.commonService.postData('buy/bid-accept', { bid_id: bid_id, charton_id: charton_id }).subscribe(
        response => {
          if (response.status) {
            $(".accept_"+charton_id).hide();
            this.errorLogService.handleSuccess(response.message);
          } else {
            this.errorLogService.handleError(response.message)
          }
        },
        error => this.errorLogService.handleError(error)
      );
  }

}
