import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material';

@Component({
    selector: 'app-confirmation-dialog',
    templateUrl: './confirmation-dialog.component.html',
    styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {

    title: string;
    message: string = 'Are you sure want to delete?';
    desc: string = 'This action is final and you will be unable to recover any data.';

    constructor(
        private bottomSheetRef: MatBottomSheetRef<ConfirmationDialogComponent>,
        @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
    ) {
        // console.log('data' + data);
        this.title = data.title;
        if(data.message != undefined){
            this.message = data.message;
            this.desc = data.desc;
        }
    }

    confirm(status: boolean = false) {
        this.bottomSheetRef.dismiss(status);
    }

}
