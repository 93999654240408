import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { ErrorLogService } from '../../../services/error-log.service';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {
  PaidHistoryList = []
  ReceivedHistoryList = []
  CurrentUserID = localStorage.getItem("UserID");
  constructor(
    private commonService: CommonService,
    private errorLogService: ErrorLogService
  ) { }

  ngOnInit() {
    this.getPaidHistoryList()
    this.getReceivedHistoryList()
  }

  getPaidHistoryList() {
    this.commonService.getData('profile/paid-history').subscribe(
      response => {
        if (response.status) {
          this.PaidHistoryList = response.data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }
  getReceivedHistoryList() {
    this.commonService.getData('profile/received-history').subscribe(
      response => {
        if (response.status) {
          this.ReceivedHistoryList = response.data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

}
