import { Directive, HostBinding, HostListener, Output, EventEmitter } from "@angular/core";

@Directive({
  selector: '[FileDrop]'
})

export class FileDropDirective {

  @Output() filesDropped = new EventEmitter<FileList>();

  @HostBinding("style.background") private background = "#eee";

  constructor() { }

  @HostListener("dragover", ["$event"]) public onDragOver(event: any) {
    // event.preventDefault();
    event.stopPropagation();
    this.background = "#ddd";
  }

  @HostListener("dragleave", ["$event"]) public onDragLeave(event: any) {
    // event.preventDefault();
    event.stopPropagation();
    this.background = "#eee";
  }

  @HostListener('drop', ['$event']) public onDrop(event: any) {
    // event.preventDefault();
    event.stopPropagation();
    this.background = '#eee';
    let transfer = event.dataTransfer;
    this.filesDropped.emit(transfer.files)
  }
}
