import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-footprint',
  templateUrl: './footprint.component.html',
  styleUrls: ['./footprint.component.css']
})
export class FootprintComponent implements OnInit {
  @ViewChild('DashboardLoginModal')
  private DashboardLoginModal: TemplateRef<any>;
  IsLogin = false;
  LoginModelRef: BsModalRef;
  loginForm: FormGroup;
  constructor(public userService: UserService,
    public formBuilder: FormBuilder,
    private modalService: BsModalService) { 
      this.loginForm = this.formBuilder.group({
        Email: ['', Validators.required],
        Password: ['', Validators.required],
      });
  
    }

  ngOnInit() {
    this.IsLogin = this.userService.isLoggedIn
  }

  
  // onLoginClick(redirect='dashboard') {
  //   localStorage.removeItem('buy-listing-id');
  //   localStorage.setItem('redirect_after_login',redirect);
  //   this.LoginModelRef = this.modalService.show(this.LoginModal, { class: 'modal-md' });
  // }
  onLoginClick(redirect='') {
    localStorage.setItem('redirect_after_login',redirect);
    this.LoginModelRef = this.modalService.show(this.DashboardLoginModal, { class: 'modal-md' });
  }

  
  onSubmitLogin(){
    this.loginForm.controls['Email'].markAsTouched()
    this.loginForm.controls['Password'].markAsTouched()
    localStorage.setItem('buy-listing-id','false')
    if (this.loginForm.valid) {
      this.userService.login(this.loginForm.value)
    }
  }

}
