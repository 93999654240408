import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  Footer: any = '';
  date:any = '';
  SiteSetting: any = [];
  constructor(private commonService: CommonService) { 
    this.date = new Date();
  }

  ngOnInit() {
    this.commonService.invokeEvent.subscribe(value => {
      if (value == 'SiteDetail') {
        this.getSiteDetail();
      }
    })
    this.getSiteDetail();
  }
  getSiteDetail() {
    this.commonService.getData('auth/setting').subscribe(
      response => {
        if (response.status) {
          this.SiteSetting = response.data;
          this.SiteSetting.forEach(element => {
            if (element.Key == 'Footer') {
              this.Footer = element.Value;
            }
          });

        }
      },
    );
  }

}
