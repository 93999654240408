import { NgModule } from '@angular/core';

@NgModule({
  imports: [
  ],
  exports: [
  ],
  declarations: [
  ],
  providers: [
  ],
})
export class AuthModule { }

