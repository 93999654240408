import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';
import { ValidationService } from '../../services/validation.service';
import {formatDate} from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.css']
})
export class SellComponent implements OnInit {
  ChartonList = []
  TodayDate = ''
  sellForm: FormGroup;
  StripeAccountID = ''
  CurrentUserID = localStorage.getItem("UserID");
  // ChartonTypeData = [
  //   { ChartonType: '', ChartonTypeLable: "Select the CHARTON", disabled: true },
  //   { ChartonType: 'bought', ChartonTypeLable: "Bought Charton", disabled: true },
  //   { ChartonType: 'unmined', ChartonTypeLable: "Unmined Charton", disabled: true }
  // ];

  constructor(
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    private router: Router,
    // private validationService: ValidationService,
    
  ) {
    this.sellForm = this.formBuilder.group({
      ChartonType: ['', Validators.required],
      SellOnAuction: [''],
      VolumeOfCharton: ['', [Validators.required, ValidationService.decimalValidation]],
      ChartonUser: ['', Validators.required],
      Price: ['', [Validators.required, ValidationService.decimalValidation]],
      ExpirtDate: [''],
    });
  }

  ngOnInit() {
    this.TodayDate = formatDate(new Date(), 'yyyy-MM-dd', 'en')
    this.getUserDetails()
  }

  getUserDetails() {
    this.commonService.getData('profile/get-user-details').subscribe(
      response => {
        if (response.status) {
          var UserData = response.data
          this.StripeAccountID = response.data.StripeAccountID
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

  onSubmit() {
    this.sellForm.controls['ChartonType'].markAsTouched()
    this.sellForm.controls['VolumeOfCharton'].markAsTouched()
    this.sellForm.controls['ChartonUser'].markAsTouched()
    this.sellForm.controls['Price'].markAsTouched()
    // ValidationService.markFormGroupTouched(this.sellForm);
    if (this.sellForm.valid) {
          this.commonService.addData('sell/sell-add', this.sellForm.value).subscribe(
            response => {
              if (response.status) {
                // this.ChartonList = response.data;
                this.errorLogService.handleSuccess(response.message);
                this.router.navigate(['/profile']);
              } else {
                this.errorLogService.handleError(response.message)
              }
            },
            error => this.errorLogService.handleError(error)
          );
    }
  }

  ChartonTypeChange(event) {
    // console.log(event.target.value)
    if (event !== undefined && event.target.value) {
      this.GetChartonList()
    }

  }

  GetChartonList() {
    this.commonService.postData('sell/get-charton', this.sellForm.value).subscribe(
      // this.commonService.postData('sell/get-charton', { CurrentUserID: this.CurrentUserID, ChartonType: Ctype, VolumeOfCharton: '' }).subscribe(
      response => {
        if (response.status) {
          this.ChartonList = response.data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

  onCheckboxChange(e) {
    if (e.target.checked) {
      // website.push(new FormControl(e.target.value));
      $("#PriceLable").html('Base Price');
      $("#ExpirtDateDiv").show();
    } else {
      $("#PriceLable").html('Price');
      $("#ExpirtDateDiv").hide();
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

}
