import { Component, OnInit } from '@angular/core';
import { ScrollTopService } from '../../services/scrolltop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';

@Component({
  selector: 'app-bid-details',
  templateUrl: './bid-details.component.html',
  styleUrls: ['./bid-details.component.css']
})
export class BidDetailsComponent implements OnInit {
  BidList = []
  // ChartonData = {}
  ChartonData = {
    'UserPicture': '',
    'CountryName': '',
    'CategoryName': '',
    'UserFirstName': '',
    'UserLastName': '',
    'OriginCountryName': '',
    'OriginCategoryName': '',
    'TotalToken': '',
    'OriginUserFirstName': '',
    'OriginUserLastName': '',
    'BasePrice': '',
    'AvailableToken': '',
    'UserBiography': '',
  }
  CurrentUserID: any = localStorage.getItem('UserID');
  ID: any = 0;
  TotalBid: any = 0;
  HighestBid: any = 0;
  LowestBid: any = 0;
  bidForm: FormGroup;
  filterForm: FormGroup;
  DisplayPayableAmount: any = 0;

  private activatedRoute: ActivatedRoute;
  constructor(
    private scrollTopService: ScrollTopService,
    activatedRoute: ActivatedRoute,
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    private router: Router,
  ) {
    this.activatedRoute = activatedRoute;
    this.ID = this.activatedRoute.snapshot.paramMap.get("ID");
    this.bidForm = this.formBuilder.group({
      HeaderID: [this.ID, Validators.required],
      BidAmount: ['', Validators.required],
    });

    this.filterForm = this.formBuilder.group({
      HeaderID: [this.ID, Validators.required],
    });
  }

  ngOnInit() {
    this.scrollTopService.setScrollTop();
    this.getChartonDetails();
    this.GetBidList();
  }

  getChartonDetails() {
    this.commonService.getData('buy/get-charton-details/' + this.ID).subscribe(
      response => {
        if (response.status) {
          this.ChartonData = response.data
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );

  }

  GetBidList() {
    this.commonService.postData('buy/get-bid-list', this.filterForm.value).subscribe(
      response => {
        if (response.status) {
          this.BidList = response.data.bid_data;
          this.TotalBid = response.data.total_bid;
          this.HighestBid = response.data.highest_bid;
          this.LowestBid = response.data.lowest_bid;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );

  }

  onSubmit() {

    this.bidForm.controls['BidAmount'].markAsTouched()
    if (this.bidForm.valid) {
      if (this.bidForm.controls['BidAmount'].value >= this.ChartonData.BasePrice) {
        this.commonService.addData('buy/add-bid', this.bidForm.value).subscribe(
          response => {
            if (response.status) {
              this.errorLogService.handleSuccess(response.message);
              // this.router.navigate(['/thank-you/' + response.data.transaction_id]);
              this.router.navigate(['/profile']);
            } else {
              this.errorLogService.handleError(response.message)
            }
          },
          error => this.errorLogService.handleError(error)
        );
      } else {
        this.errorLogService.handleError('Bid price should not be lower then Base price.')
      }
    }
  }

}
