import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ErrorLogService } from './error-log.service';
import { ILogin } from '../shared/interfaces';
import { appApi, appHeader } from "../shared/app.constants";
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { each } from 'jquery';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || 'false');

  constructor(
    private http: HttpClient,
    private router: Router,
    private errorLogService: ErrorLogService,
    private spinner: NgxSpinnerService,
    private modalService: BsModalService,
    public confirmationDialogService: ConfirmationDialogService) { }

  login(data) {
    // let input = new FormData();
    // for (var key in data) {
    //   if (typeof data[key] == 'object') {
    //     var details = JSON.stringify(data[key]);
    //     input.append(key, details);
    //   } else {
    //     input.append(key, data[key]);
    //   }
    // }
    this.spinner.show();
    return this.http.post<ILogin>(appApi.login, data).subscribe(
      (result) => {
        this.spinner.hide();
        if (result.status) {
          // if (result.data.RoleID == '5') {
          //   result.data.RoleID = '4';
          // }
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('ID', result.data._id);
          localStorage.setItem('UserID', result.data.UserID);
          localStorage.setItem('Username', result.data.UserName);
          localStorage.setItem('Name', result.data.UserFirstName + ' ' + result.data.UserLastName);
          localStorage.setItem('Email', result.data.UserEmail);
          localStorage.setItem('RoleID', result.data.UserGroup);
          localStorage.setItem('ProfileImage', result.data.UserPicture);
          localStorage.setItem('Token', result.token);
          console.log(localStorage.getItem('redirect_after_login'),' ------ redirect_after_login')
          console.log(localStorage.getItem('buy-listing-id'),' ------ buy-listing-id')
          if (localStorage.getItem('redirect_after_login')) {
            $('.close_modal').trigger('click');
            this.router.navigate(['/' + localStorage.getItem('redirect_after_login')])
            setTimeout(() => {
              localStorage.setItem('redirect_after_login','false')
                window.location.reload()
            }, 500);
          } else if (localStorage.getItem('buy-listing-id')) {
            $('.close_modal').trigger('click');
            this.router.navigate(['/'])
            setTimeout(() => {
              window.location.reload()
            }, 500);

          } else {
            window.location.reload();
            setTimeout(() => {
              window.location.reload()
            }, 500);
            $('.close_modal').trigger('click');
          }
          // localStorage.setItem('buy-listing-id', '0');
          this.errorLogService.handleSuccess(result.message);



        } else {
          this.errorLogService.handleError(result.message)
        }
      },
      (error) => this.handleError(error)
    );

  }

  auto_login(data) {
    // let input = new FormData();
    // for (var key in data) {
    //   if (typeof data[key] == 'object') {
    //     var details = JSON.stringify(data[key]);
    //     input.append(key, details);
    //   } else {
    //     input.append(key, data[key]);
    //   }
    // }
    this.spinner.show();
    return this.http.post<ILogin>(appApi.auto_login, data).subscribe(
      (result) => {
        this.spinner.hide();
        if (result.status) {
          console.log(result, ' auto login results')
          // if (result.data.RoleID == '5') {
          //   result.data.RoleID = '4';
          // }
          localStorage.setItem('loggedIn', 'false');
          localStorage.setItem('ID', 'false');
          localStorage.setItem('UserID', 'false');
          localStorage.setItem('Username', 'false');
          localStorage.setItem('Name', 'false');
          localStorage.setItem('Email', 'false');
          localStorage.setItem('RoleID', 'false');
          localStorage.setItem('ProfileImage', 'false');
          localStorage.setItem('Token', 'false');
          localStorage.setItem('loggedIn', 'true');
          localStorage.setItem('ID', result.data._id);
          localStorage.setItem('UserID', result.data.UserID);
          localStorage.setItem('Username', result.data.UserName);
          localStorage.setItem('Name', result.data.UserFirstName + ' ' + result.data.UserLastName);
          localStorage.setItem('Email', result.data.UserEmail);
          localStorage.setItem('RoleID', result.data.UserGroup);
          localStorage.setItem('ProfileImage', result.data.UserPicture);
          localStorage.setItem('Token', result.token);
          console.log(localStorage, ' localStorage')
          this.errorLogService.handleSuccess(result.message);
          this.router.navigate(['/'])

        } else {
          this.errorLogService.handleError(result.message)
          this.router.navigate(['/'])
        }
      },
      (error) => {
        this.errorLogService.handleError(error)
        this.router.navigate(['/'])
      }
      // this.handleError(error)
    );

  }

  get isLoggedIn() {
    return JSON.parse(localStorage.getItem('loggedIn') || this.loggedInStatus.toString())
  }

  logout() {
    this.spinner.show();
    return this.http.get<ILogin>(appApi.logout, appHeader).subscribe(
      (result) => {
        this.spinner.hide();
        if (result.status) {
          localStorage.setItem('loggedIn', 'false');
          localStorage.setItem('ID', 'false');
          localStorage.setItem('UserID', 'false');
          localStorage.setItem('Username', 'false');
          localStorage.setItem('Name', 'false');
          localStorage.setItem('Email', 'false');
          localStorage.setItem('RoleID', 'false');
          localStorage.setItem('ProfileImage', 'false');
          localStorage.setItem('Token', 'false');
          this.errorLogService.handleSuccess(result.message)
          // alert('Kindly Log out from Social Platform too.')
          // this.confirmationDialogService.confirm('Kindly Log out from Social Platform too.', (response: boolean) => {
          //   if (response) {

          //     this.router.navigate(['/'])
          //   }else{
          //     this.router.navigate(['/'])

          //   }
          // });
          this.router.navigate(['/'])
        } else {
          this.errorLogService.handleError(result.message)
        }
      },
      (error) => this.handleError(error)
    );
  }

  forgotPassword(data) {
    this.spinner.show();
    return this.http.post<ILogin>(appApi.forgotPassword, data).subscribe(
      (result) => {
        this.spinner.hide();
        if (result.status) {
          this.errorLogService.handleSuccess(result.message);
          this.router.navigate(['/login'])
        } else {
          this.errorLogService.handleError(result.message)
        }
      },
      (error) => this.handleError(error)
    );
  }
  // SiteSetting() {
  //   this.spinner.show();
  //   return this.http.get<ILogin>(appApi.setting).subscribe(
  //     (result) => {
  //       this.spinner.hide();
  //       if (result.status) {
  //         // this.errorLogService.handleSuccess(result.message);
  //         return result.data;
  //       } else {
  //         this.errorLogService.handleError(result.message)
  //       }
  //     },
  //     (error) => this.handleError(error)
  //   );
  // }

  changePassword(data) {
    this.spinner.show();
    return this.http.post<ILogin>(appApi.changePassword, data, appHeader).subscribe(
      (result) => {
        this.spinner.hide();
        if (result.status) {
          this.errorLogService.handleSuccess(result.message);
          this.router.navigate(['/'])
        } else {
          this.errorLogService.handleError(result.message)
        }
      },
      (error) => this.handleError(error)
    );
  }

  public roleCheck(role_id): boolean {
    var isMatch = false;
    var userRole: string[] = JSON.parse(localStorage.getItem('RoleID'));
    role_id.forEach(element => {
      if (userRole.toString().indexOf(element) > -1) {
        // console.log(userRole.toString().indexOf(element));
        isMatch = true;
        return false;
      }
    });
    return isMatch;
  }

  private handleError(error) {
    this.spinner.hide();
    this.errorLogService.handleError(error);
  }

}