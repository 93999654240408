import { Injectable } from '@angular/core';
import { MatBottomSheet } from '@angular/material';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';

@Injectable()
export class ConfirmationDialogService {

    constructor(private bottomSheet: MatBottomSheet) { }

    public confirm(title, callback) {
        // console.log('data 1213231312',title);
        const modalRef = this.bottomSheet.open(ConfirmationDialogComponent, {
            data: { title: title }
        });
        modalRef.afterDismissed().subscribe(status => {
            callback(status);
        });
    }
    public Paymentconfirm(title, message, desc, callback) {
        // console.log('data 1213231312',title);
        const modalRef = this.bottomSheet.open(ConfirmationDialogComponent, {
            data: { title: title, message: message, desc:desc }
        });
        modalRef.afterDismissed().subscribe(status => {
            callback(status);
        });
    }
    public template_confirm(data, callback) {
        // console.log('data',data);
        const modalRef = this.bottomSheet.open(ConfirmationDialogComponent, {
            data: { title: data.title, message: data.message, desc:data.data}
        });
        modalRef.afterDismissed().subscribe(status => {
            callback(status);
        });
    }

}
