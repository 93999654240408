import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from './shared/shared.module';
import { AuthGuard } from './shared/auth.guard';

// import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { SiteSettingDialogComponent } from './components/layout/header/setting-dialog.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TradingPolicyComponent } from './components/trading-policy/trading-policy.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { FaqComponent } from './components/faq/faq.component';
import { BuyListingComponent } from './components/buy-listing/buy-listing.component';
// import { SellListingComponent } from './components/sell-listing/sell-listing.component';
import { SellComponent } from './components/sell/sell.component';
import { BuyDetailsComponent } from './components/buy-details/buy-details.component';
import { BidComponent } from './components/bid/bid.component';
import { BidDetailsComponent } from './components/bid-details/bid-details.component';
import { SellHistoryComponent } from './components/sell-history/sell-history.component';
import { BidRequestComponent } from './components/bid-request/bid-request.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { LoginComponent } from './components/auth/login/login.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { UnMinedComponent } from './components/profile/un-mined/un-mined.component';
import { BoughtComponent } from './components/profile/bought/bought.component';
import { SoldComponent } from './components/profile/sold/sold.component';
import { PaymentHistoryComponent } from './components/profile/payment-history/payment-history.component';
import { MyBidComponent } from './components/my-bid/my-bid.component';
import { CancelChartonsComponent } from './components/cancel-chartons/cancel-chartons.component';
import { CanceledChartonsComponent } from './components/profile/canceled-chartons/canceled-chartons.component';
import { StripeAccountComponent } from './components/stripe-account/stripe-account.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { BiocharComponent } from './components/biochar/biochar.component';
import { UnMiningComponent } from './components/un-mining/un-mining.component';
import { Co2Component } from './components/co2/co2.component';
import { BonusComponent } from './components/bonus/bonus.component';
import { FootprintComponent } from './components/footprint/footprint.component';
import { ProjectComponent } from './components/project/project.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';

import { from } from 'rxjs';

const routes: Routes = [
  { path: 'page-not-found', component: PageNotFoundComponent, data: { title: 'Page not found' }, canActivate: [] },
  { path: '', component: DashboardComponent, data: { title: 'Dashboard' } },
  { path: 'dashboard', component: DashboardComponent, data: { title: 'Dashboard' } },
  // { path: ':about_us', component: DashboardComponent, data: { title: 'Dashboard' } },
  // { path: ':benifit', component: DashboardComponent, data: { title: 'Dashboard' } },
  // { path: ':how_work', component: DashboardComponent, data: { title: 'Dashboard' } },
  { path: 'login/:id', component: LoginComponent, data: { title: 'Login' } },
  { path: 'privacy-policy', component: PrivacyPolicyComponent, data: { title: 'Privacy Policy' }, canActivate: [] },
  { path: 'trading-policy', component: TradingPolicyComponent, data: { title: 'Trading Policy' }, canActivate: [] },
  { path: 'terms-conditions', component: TermsConditionsComponent, data: { title: 'Terms Conditions' }, canActivate: [] },
  { path: 'faq', component: FaqComponent, data: { title: 'FAQ' }, canActivate: [] },
  { path: 'buy-listing', component: BuyListingComponent, data: { title: 'Buy Listing' }, canActivate: [AuthGuard] },
  { path: 'sell', component: SellComponent, data: { title: 'Sell Listing' }, canActivate: [AuthGuard] },
  { path: 'cancel-chartons', component: CancelChartonsComponent, data: { title: 'Cancel Chartons' }, canActivate: [AuthGuard] },
  { path: 'buy-details/:ID', component: BuyDetailsComponent, data: { title: 'Buy Details' }, canActivate: [] },
  { path: 'bid', component: BidComponent, data: { title: 'Bid' }, canActivate: [] },
  { path: 'bid-details/:ID', component: BidDetailsComponent, data: { title: 'Bid Details' }, canActivate: [] },
  { path: 'sell-history', component: SellHistoryComponent, data: { title: 'Sell History' }, canActivate: [] },
  { path: 'bid-request', component: BidRequestComponent, data: { title: 'Bid Request' }, canActivate: [AuthGuard] },
  { path: 'my-bid', component: MyBidComponent, data: { title: 'My Bid' }, canActivate: [AuthGuard] },
  { path: 'profile', component: ProfileComponent, data: { title: 'Profile' }, canActivate: [AuthGuard] },
  { path: 'thank-you/:ID', component: ThankYouComponent, data: { title: 'thank-you' }, canActivate: [] },
  { path: 'sitemap', component: SitemapComponent, data: { title: 'Sitemap' }, canActivate: [] },
  { path: 'stripe-account', component: StripeAccountComponent, data: { title: 'Stripe Account' }, canActivate: [AuthGuard] },
  { path: 'about-us', component: AboutUsComponent, data: { title: 'About Us' }, canActivate: [] },
  { path: 'biochar', component: BiocharComponent, data: { title: 'Biochar' }, canActivate: [] },
  { path: 'un-mining', component: UnMiningComponent, data: { title: 'Un-Mining' }, canActivate: [] },
  { path: 'co2', component: Co2Component, data: { title: 'CO2' }, canActivate: [] },
  { path: 'bonus', component: BonusComponent, data: { title: 'Bonus' }, canActivate: [] },
  { path: 'footprint', component: FootprintComponent, data: { title: 'Footprint' }, canActivate: [] },
  { path: 'project', component: ProjectComponent, data: { title: 'Project' }, canActivate: [] },
  { path: 'calculator', component: CalculatorComponent, data: { title: 'Calculator' }, canActivate: [] },
  { path: 'contact-us', component: ContactUsComponent, data: { title: 'Contact US' }, canActivate: [] },
  
  { path: '**', redirectTo: '/page-not-found' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    SharedModule
  ],
  exports: [
    LoginComponent,
    RouterModule,
    SiteSettingDialogComponent,
    HeaderComponent,
    FooterComponent,
    PageNotFoundComponent,
    DashboardComponent,
    PrivacyPolicyComponent,
    TradingPolicyComponent,
    TermsConditionsComponent,
    FaqComponent,
    BuyListingComponent,
    SellComponent,
    CancelChartonsComponent,
    BuyDetailsComponent,
    BidComponent,
    BidDetailsComponent,
    SellHistoryComponent,
    BidRequestComponent,
    MyBidComponent,
    ProfileComponent,
    ThankYouComponent,
    SitemapComponent,
    UnMinedComponent,
    BoughtComponent,
    SoldComponent,
    CanceledChartonsComponent,
    PaymentHistoryComponent,
    StripeAccountComponent,
    AboutUsComponent,
    BiocharComponent,
    UnMiningComponent,
    Co2Component,
    BonusComponent,
    FootprintComponent,
    ProjectComponent,
    CalculatorComponent,
    ContactUsComponent
  ],
  declarations: [
    LoginComponent,
    HeaderComponent,
    SiteSettingDialogComponent,
    FooterComponent,
    PageNotFoundComponent,
    DashboardComponent,
    PrivacyPolicyComponent,
    TradingPolicyComponent,
    TermsConditionsComponent,
    FaqComponent,
    BuyListingComponent,
    SellComponent,
    CancelChartonsComponent,
    BuyDetailsComponent,
    BidComponent,
    BidDetailsComponent,
    SellHistoryComponent,
    BidRequestComponent,
    MyBidComponent,
    ProfileComponent,
    ThankYouComponent,
    SitemapComponent,
    UnMinedComponent,
    BoughtComponent,
    SoldComponent,
    CanceledChartonsComponent,
    PaymentHistoryComponent,
    StripeAccountComponent,
    AboutUsComponent,
    BiocharComponent,
    UnMiningComponent,
    Co2Component,
    BonusComponent,
    FootprintComponent,
    ProjectComponent,
    CalculatorComponent,
    ContactUsComponent
  ],
  entryComponents: [UnMinedComponent, BoughtComponent, SoldComponent, PaymentHistoryComponent, CanceledChartonsComponent]
})
export class AppRoutingModule {}
