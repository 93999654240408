import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Observable, of as observableOf } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { UserService } from './services/user.service';
import { CommonService } from './services/common.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  // showHead: Observable<boolean>;
  showHead: boolean = false;
  SiteSetting: any;
  SiteName = 'Carbonface';
  constructor(
    private router: Router,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private titleService: Title) {
    // this.showHead = this.userService.isLoggedIn;
    // on route change to '/login', set the variable showHead to false

    this.showHead = true;

    // router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     if (event['url'] == '/login' || event['url'] == '/forgot-password' || event['url'] == '/change-password' || event['url'] == '/page-not-found') {
    //       this.showHead = false;
    //       // this.showHead = this.userService.isLoggedIn;
    //     } else {
    //       this.showHead = true;
    //       // this.showHead = this.userService.isLoggedIn;
    //     }
    //   }
    // });
  }
  // getSiteDetail() {
  //   this.commonService.getData('auth/setting').subscribe(
  //     response => {
  //       if (response.status) {
  //         this.SiteSetting = response.data;
  //         this.SiteSetting.forEach(element => {
  //           if (element.Key == 'SiteName') {
  //             this.SiteName = element.Value;
  //           }
  //         });

  //       }
  //     },
  //   );
  // }
  ngOnInit() {
    // this.getSiteDetail()
    // this.commonService.invokeEvent.subscribe(value => {
    //   if (value == 'SiteDetail') {
    //     // this.getSiteDetail();
    //   }
    // })
    window.addEventListener('storage', (event) => {
      // console.log("storageArea", event.storageArea)
      // console.log("localStorage", localStorage)
      if (event.storageArea.loggedIn == 'true') {
        localStorage.setItem('loggedIn', event.storageArea.loggedIn);
        localStorage.setItem('UserID', event.storageArea.UserID);
        localStorage.setItem('Username', event.storageArea.Username);
        localStorage.setItem('RoleID', event.storageArea.RoleID);
        localStorage.setItem('Token', event.storageArea.Token);
        setTimeout(() => {
          this.router.navigate(['/'])
        }, 800);
      } else {
        // this.showHead = false;
        this.router.navigate(['/login'])
      }
      // window.location.reload()
    })
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {

        while (route.firstChild) {
          route = route.firstChild;
        };
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe((event) => this.titleService.setTitle(event['title'] + " - " + this.SiteName));
  }
}