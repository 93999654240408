import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { ErrorLogService } from '../../../services/error-log.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-un-mined',
  templateUrl: './un-mined.component.html',
  styleUrls: ['./un-mined.component.css']
})
export class UnMinedComponent implements OnInit {
  ChartonList = []
  CurrentUserID = localStorage.getItem("UserID");
  filterForm: FormGroup;
  constructor(
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    public formBuilder: FormBuilder,
  ) {
    this.filterForm = this.formBuilder.group({
      FromDate: [''],
      ToDate: [''],
    });
  }

  ngOnInit() {
    this.getChartonList()
  }

  getChartonList() {
    this.commonService.postData('profile/unmined', this.filterForm.value).subscribe(
      response => {
        if (response.status) {
          this.ChartonList = response.data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

  ChangeOrder(value) {
    var array = this.ChartonList
    // if(value == 1) {
    //   array.sort((a, b) => b.NoOfCartons.localeCompare(a.NoOfCartons));
    // } else {
    //   array.sort((a, b) => a.NoOfCartons.localeCompare(b.NoOfCartons));
    // }
    if(value == 1) {
      array.sort((a, b) => b.NoOfCartons - a.NoOfCartons);
    } else {
      array.sort((a, b) => a.NoOfCartons - b.NoOfCartons);
    }
  }

}
