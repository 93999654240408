import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../services/user.service';
import { CommonService } from 'src/app/services/common.service';
import { appURL } from "../../../shared/app.constants";
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  title = "Login";
  loginForm: FormGroup;
  LogoPreview: string;
  id: number;
  private sub: any;
  
  constructor(
    private userService: UserService,
    private commonService: CommonService,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) {
  }

  ngOnInit() {

    this.sub = this.route.params.subscribe(params => {
      console.log(params['id'])
      this.id = +params['id']; // (+) converts string 'id' to a number

      // In a real app: dispatch action to load the details here.
   });

  //  console.log(this.id);

    // if (this.userService.isLoggedIn) {
    //   this.router.navigate(['/'])
    // }
    this.loginForm = this.formBuilder.group({
      UserKey: [this.id, Validators.required]
    });

    if (this.loginForm.valid) {
      this.userService.auto_login(this.loginForm.value)
    }
    // this.getSiteDetail()
  }
  // onSubmit() {
  //   this.loginForm.controls['Email'].markAsTouched()
  //   this.loginForm.controls['Password'].markAsTouched()
  //   if (this.loginForm.valid) {
  //     this.userService.login(this.loginForm.value)
  //   }
  // }
  // getSiteDetail() {
  //   this.commonService.getData('auth/setting').subscribe(
  //     response => {
  //       if (response.status) {
  //         response.data.forEach(element => {
  //           console.log('element', element)
  //           if (element.Key == 'Logo') {
  //             this.LogoPreview = appURL.logoImagesPath + element.Value;
  //           }
  //         });

  //       }
  //     },
  //   );
  // }
}
