import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { ErrorLogService } from '../../../services/error-log.service';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-sold',
  templateUrl: './sold.component.html',
  styleUrls: ['./sold.component.css']
})
export class SoldComponent implements OnInit {
  ChartonList = []
  CurrentUserID = localStorage.getItem("UserID");
  filterForm: FormGroup;
  constructor(
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    public formBuilder: FormBuilder,
  ) {
    this.filterForm = this.formBuilder.group({
      FromDate: [''],
      ToDate: [''],
    });
   }

  ngOnInit() {
    this.getChartonList()
  }

  getChartonList() {
    this.commonService.postData('profile/sold', this.filterForm.value).subscribe(
      response => {
        if (response.status) {
          this.ChartonList = response.data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

  ChangeOrder(value) {
    var array = this.ChartonList
    if(value == 1) {
      array.sort((a, b) => b.TotalToken - a.TotalToken);
    } else {
      array.sort((a, b) => a.TotalToken - b.TotalToken);
    }
  }

}
