import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { CommonService } from '../../../services/common.service';
import { ErrorLogService } from '../../../services/error-log.service';
import { ValidationService } from '../../../services/validation.service';
import { appURL } from "../../../shared/app.constants";

@Component({
    selector: 'app-setting-dialog',
    templateUrl: './setting-dialog.component.html'
})
export class SiteSettingDialogComponent {

    title = "Site Setting";
    siteForm: FormGroup;
    files: '';
    preview: string;
    system = [];
    group = [];
    modality = [];

    constructor(
        public dialogRef: MatDialogRef<SiteSettingDialogComponent>,
        private commonService: CommonService,
        public formBuilder: FormBuilder,
        private errorLogService: ErrorLogService, ) {
    }

    ngOnInit() {
        this.siteForm = this.formBuilder.group({
            UserID: [localStorage.getItem('UserID'), Validators.required],
            SiteName: ['', Validators.required],
            Logo: ['', Validators.required],
            Link: ['', Validators.required],
            Email: ['', [Validators.required,ValidationService.emailValidator]]
        });
        this.setting();
    }

    onSubmit() {
        ValidationService.markFormGroupTouched(this.siteForm);
        if (this.siteForm.valid) {
            const formData: FormData = new FormData();
            formData.append("Logo", this.files);
            formData.append("UserID", this.siteForm.controls['UserID'].value);
            formData.append("SiteName", this.siteForm.controls['SiteName'].value);
            formData.append("Email", this.siteForm.controls['Email'].value);
            formData.append("Link", this.siteForm.controls['Link'].value);
            this.commonService.updateData('site-setting', formData).subscribe(
                response => {
                    if (response.status) {
                        this.errorLogService.handleSuccess(response.message);
                        this.close();
                    } else {
                        this.errorLogService.handleError(response.message)
                    }
                },
                error => this.errorLogService.handleError(error)
            );
        }
    }

    setting() {
        this.commonService.getData('site-setting/' + localStorage.getItem('UserID')).subscribe(
            response => {
                if (response.status) {
                    this.siteForm = this.formBuilder.group({
                        UserID: [response.data.UserID, Validators.required],
                        SiteName: [response.data.SiteName, Validators.required],
                        Email: [response.data.Email, [Validators.required, ValidationService.emailValidator]],
                        Link: [response.data.Link, Validators.required],
                        Logo: [response.data.Logo, Validators.required]
                    });
                    ValidationService.markFormGroupTouched(this.siteForm);
                    this.preview = appURL.logoImagesPath + response.data.Logo;
                }
            },
            error => this.errorLogService.handleError(error)
        );
    }

    onFileChange(file) {
        if (file.length) {
            this.files = file[0];
            this.siteForm.controls['Logo'].setValue(this.files['name']);
            var reader = new FileReader();
            reader.readAsDataURL(file[0]);
            reader.onload = (event: any) => {
                this.preview = event.target.result;
            }
        }
    }

    close() {
        this.dialogRef.close();
    }
}