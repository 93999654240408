import { Component, OnInit,ViewChild, TemplateRef  } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { createStripeKey } from "../../shared/app.constants";
import { ValidationService } from '../../services/validation.service';
import { ConfirmationDialogService } from '../../services/confirmation-dialog.service';
import { UserService } from 'src/app/services/user.service';
import { appURL } from "../../shared/app.constants";
import { each } from 'jquery';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('DashboardLoginModal')
  private DashboardLoginModal: TemplateRef<any>;
  LoginModelRef: BsModalRef;
  SellChartonList = []
  StripeAccountID = ''
  UnminedCountryList = ''
  TheUnMiners = [
    'image1081.jpg',
    'image820.jpg',
    'image850.jpg',
    'image1070-6.jpg',
    'image861.jpg',
    'image872.jpg',
    'image1059-0.jpg',
    'image883.jpg',
    'image894.jpg',
    'image1092.jpg',
    'image1059.jpg',
    'image916.jpg',
    'image927.jpg',
    'image1015-1.jpg',
    'image938.jpg',
    'image949.jpg',
    'image960.jpg',
    'image971.jpg',
    'image982.jpg',
    'image993.jpg',
    'image1004.jpg',
    'image1015.jpg',
    'image1026.jpg',
    'image1037.jpg',
    'image1048.jpg',
    'image1070.jpg',
    'image1114.jpg',
    'image1048-6.jpg',
    'image1103.jpg',
    'image1081-4.jpg',
    'image905.jpg',
    'image1037-9.jpg',
    'image839.jpg',
    'image1026-9.jpg'
  ]
  ChartonList = []
  CountryList = []
  CategoryList = []
  AdminFeePer: any = 0
  AdminFeeAmount: any = 0
  selectedCountry: any = 0
  ChartonData = {
    'BasePrice': '',
    'AvailableToken': '',
  }
  AvailableQuantity: any = 0;
  DisplayPayableAmount: any = 0;
  SellDisplayPayableAmount: any = 0;
  buyForm: FormGroup;
  sellForm: FormGroup;
  IsLogin = false;
  SocialSite: string = appURL.SocialSite;
  loginForm: FormGroup;
  constructor(
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    private router: Router,
    public userService: UserService,
    private route: ActivatedRoute,
    public confirmationDialogService: ConfirmationDialogService,
    private modalService: BsModalService,
  ) {
    this.commonService.ChangeUpdate('PageChange');
    this.buyForm = this.formBuilder.group({
      // SellType: ['fixed', Validators.required],
      Country: [''],
      Category: [''],
      HeaderID: ['', Validators.required],
      PayableAmount: ['', Validators.required],
      BuyQuantity: ['', [Validators.required, ValidationService.decimalValidation]],
      StripeToken: [''],
      BasePrice: [''],
    });
    this.loginForm = this.formBuilder.group({
      Email: ['', Validators.required],
      Password: ['', Validators.required],
    });

    this.sellForm = this.formBuilder.group({
      ChartonType: ['', Validators.required],
      SellOnAuction: [''],
      VolumeOfCharton: ['', [Validators.required, ValidationService.decimalValidation]],
      ChartonUser: ['', Validators.required],
      Price: ['', [Validators.required, ValidationService.decimalValidation]],
      ExpirtDate: [''],
    });

  }

  ngOnInit() {
    var buy_lising_id = localStorage.getItem('buy-listing-id');
    // if(localStorage.getItem('buy-listing-id')){
    //     var $panel = $('.buy_sell_box');
    //     $('html,body').animate({
    //       scrollTop: $panel.offset().top
    //     }, 500); 
    //     localStorage.setItem('buy-listing-id', 'false');
    // }
    this.commonService.postData('users/updateAllUser', {}).subscribe(
      response => {
        if (response.status) {
          
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
    this.IsLogin = this.userService.isLoggedIn
    this.commonService.invokeEvent.subscribe(value => {
      this.IsLogin = this.userService.isLoggedIn
    })
    if (this.IsLogin) {
      if (localStorage.getItem('country-id')) {
        // this.selectedCountry =localStorage.getItem('country-id')
        this.buyForm.controls.Country.setValue(localStorage.getItem('country-id'))
      }


      this.GetChartonList();

      this.GetSocialData();
      this.getSiteDetail();
      this.getUserDetails();
    } else {
      this.router.navigate(['/'])
    }


    setTimeout(() => {
      $(document).ready(function () {
        ($('.bxslider') as any).bxSlider({
          auto: true,
          pager: true,
          autoControls: false,
          speed: 1500,
          mode: 'fade',
          autoHover: true
        });

        ($(".nav-tabs a") as any).click(function () {
          ($(this) as any).tab('show');
        });

        ($("#the_un_miners") as any).owlCarousel(
          {
            items: 5,
            lazyLoad: true,
            loop: true,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true,
            dots: false,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            autoHeight: true,
            nav: false,
            // navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
            responsiveClass: true,
            responsive:
            {
              0: {
                items: 2
              },
              640: {
                items: 2
              },
              641: {
                items: 4
              },
              1024: {
                items: 5
              }
            }
          });

        // ($('[data-toggle="tooltip"]') as any).tooltip();
      })
    }, 500);
  }

  getUserDetails() {
    this.commonService.getData('profile/get-user-details').subscribe(
      response => {
        if (response.status) {
          var UserData = response.data
          this.StripeAccountID = response.data.StripeAccountID
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

  GetSocialData() {
    // this.commonService.getSocialData('common_list.php').subscribe(
    //   response => {
    //     if (response.status) {
    //       // var UserData = response.data.UsersData
    //       this.CategoryList = response.data.categories;
    //       this.CountryList = response.data.country;
    //     } else {
    //       this.errorLogService.handleError(response.message)
    //     }
    //   },
    //   error => this.errorLogService.handleError(error)
    // );
    this.commonService.postData('buy/get-unmined-country', {}).subscribe(
      response => {
        if (response.status) {
          this.UnminedCountryList = response.data.charton_data.join();
          this.commonService.getSocialData('common_list.php?ids='+this.UnminedCountryList).subscribe(
            response =>  {
              if (response.status) {
               
                this.CategoryList = response.data.categories;
                this.CountryList = response.data.unminers_country;
              } else {
                this.errorLogService.handleError(response.message)
              }
            },
            error => this.errorLogService.handleError(error)
          );
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

  GetChartonList() {
    this.buyForm.controls.HeaderID.setValue('')
    this.ChartonData = {
      'BasePrice': '',
      'AvailableToken': '',
    }
    this.buyForm.controls.PayableAmount.setValue(0)
    this.DisplayPayableAmount = 0
    this.AvailableQuantity = 0
    this.buyForm.controls.BuyQuantity.setValue('0')
    this.commonService.postData('buy/get-buy-charton', this.buyForm.value).subscribe(
      response => {
        if (response.status) {
          this.ChartonList = response.data.charton_data;
          if (localStorage.getItem('buy-listing-id')) {
            console.log(localStorage.getItem('buy-listing-id'), 'buy-listing-id')
            let max;
            let i = 0;
            this.ChartonList.forEach((item) => {
              console.log(item,'item')
              if(item.UserID == localStorage.getItem('buy-listing-id')){
                if (i == 0) {
                  max = item
                }else{
                  if(item.AvailableToken > max.AvailableToken){
                    max = item
                  }
                }
                i++;
              }
              
            });
            if(max){
              console.log(max,'max')
              if(max._id){
                this.buyForm.controls.HeaderID.setValue(max._id)
              }
            }
            // this.buyForm.controls.HeaderID.setValue(localStorage.getItem('buy-listing-id'))
            this.getChartonDetails()
            localStorage.setItem('buy-listing-id', 'false');
            localStorage.setItem('country-id', 'false');
            var $panel = $('.buy_sell_box');
            $('html,body').animate({
                scrollTop: $panel.offset().top
            }, 500); 
          }
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

  getChartonDetails() {
    // console.log(this.buyForm.controls['HeaderID'].value)
    this.buyForm.controls.PayableAmount.setValue(0)
    this.DisplayPayableAmount = 0
    this.AvailableQuantity = 0
    this.buyForm.controls.BuyQuantity.setValue('0')
    if (this.buyForm.controls['HeaderID'].value > 0) {
      this.commonService.getData('buy/get-charton-details/' + this.buyForm.controls['HeaderID'].value).subscribe(
        response => {
          if (response.status) {
            this.ChartonData = response.data
            console.log(response.data, 'response.data ------------------')
            // this.buyForm.controls.HeaderID.setValue('')
            let AvailableToken: number = Number(this.ChartonData['AvailableToken'])
            this.buyForm.controls.BuyQuantity.setValue(AvailableToken / 100)
            this.buyForm.controls.BasePrice.setValue(response.data.BasePrice)
            this.AvailableQuantity = AvailableToken / 100
            this.PayableAmount()
          } else {
            this.errorLogService.handleError(response.message)
          }
        },
        error => this.errorLogService.handleError(error)
      );
    } else {
      this.buyForm.controls.BasePrice.setValue('')
      this.ChartonData = {
        'BasePrice': '',
        'AvailableToken': '',
      }
    }

  }

  PayableAmount() {
    let TotalToken = Number(this.ChartonData['TotalToken'])
    let TotalPrice = Number(this.ChartonData['BasePrice'])
    let PerTokenPrice = TotalPrice / TotalToken
    let BuyQuantityToken = this.buyForm.controls['BuyQuantity'].value
    this.DisplayPayableAmount = BuyQuantityToken * TotalPrice;
    this.buyForm.controls.PayableAmount.setValue(this.DisplayPayableAmount)
  }

  SellPayableAmount() {
    let TotalPayment = (Number(this.sellForm.controls['VolumeOfCharton'].value) * Number(this.sellForm.controls['Price'].value))
    this.AdminFeeAmount = (this.AdminFeePer * TotalPayment) / 100;
    this.SellDisplayPayableAmount = TotalPayment - this.AdminFeeAmount;
  }

  onSubmit() {

    this.buyForm.controls['BuyQuantity'].markAsTouched()
    this.buyForm.controls['HeaderID'].markAsTouched()
    if (this.buyForm.valid) {
      var AvailableToken = Number(this.ChartonData.AvailableToken)
      var BuyQuantityToken = Number(this.buyForm.controls['BuyQuantity'].value) * 100
      if (BuyQuantityToken <= AvailableToken) {

        var html = '<div class="thanks_details thanks_details_popup pl-3">'
        html += '<div class="table-responsive">'
        html += '<table class="table" style="text-align: center;">'
        html += '<tbody>'
        html += '<tr>'
        html += '<td>CHARTON Quantity</td>'
        html += '<td><label>' + this.buyForm.controls['BuyQuantity'].value + '</label></td>'
        html += '</tr>'
        html += '<tr>'
        html += '<td>Payable Amount</td>'
        html += '<td><label>$ ' + this.buyForm.controls['PayableAmount'].value + '</label></td>'
        html += '</tr>'
        html += '</tbody>'
        html += '</table>'
        html += '</div>'
        html += '</div>'

        this.confirmationDialogService.Paymentconfirm('Confirm', 'Are you sure to continue purchase the CHARTON.', html, (response: boolean) => {
          if (response) {

            let that = this
            var handler = (<any>window).StripeCheckout.configure({
              key: createStripeKey(),
              locale: 'auto',
              token: function (token: any) {
                // You can access the token ID with `token.id`.
                // Get the token ID to your server-side code for use.
                // console.log(token)
                // alert('Token Created!!');
                that.buyForm.controls.StripeToken.setValue(token.id)
                // that.buyForm.controls.BasePrice.setValue(token.id)
                that.commonService.addData('buy/buy-charton', that.buyForm.value).subscribe(
                  response => {
                    if (response.status) {
                      that.errorLogService.handleSuccess(response.message);
                      that.router.navigate(['/thank-you/' + response.data.transaction_id]);
                    } else {
                      that.errorLogService.handleError(response.message)
                    }
                  },
                  error => that.errorLogService.handleError(error)
                );
              }
            });
            var amount = this.buyForm.controls['PayableAmount'].value
            handler.open({
              name: 'Carbonface',
              // email: 'test1@test.com',
              description: 'Buy Charton',
              amount: amount * 100
            });
          }
        });

      } else {
        this.errorLogService.handleError('CHARTON Quantity Not Available.')
      }

    }
  }

  ChartonTypeChange(event) {
    // console.log(event.target.value)
    if (event !== undefined && event.target.value) {
      this.GetSellChartonList()
    }

  }

  GetSellChartonList() {
    this.commonService.postData('sell/get-charton', this.sellForm.value).subscribe(
      // this.commonService.postData('sell/get-charton', { CurrentUserID: this.CurrentUserID, ChartonType: Ctype, VolumeOfCharton: '' }).subscribe(
      response => {
        if (response.status) {
          this.SellChartonList = response.data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

  onSubmitSell() {
    this.sellForm.controls['ChartonType'].markAsTouched()
    this.sellForm.controls['VolumeOfCharton'].markAsTouched()
    this.sellForm.controls['ChartonUser'].markAsTouched()
    this.sellForm.controls['Price'].markAsTouched()
    // ValidationService.markFormGroupTouched(this.sellForm);
    if (this.sellForm.valid) {

      this.commonService.addData('sell/sell-add', this.sellForm.value).subscribe(
        response => {
          if (response.status) {
            // this.ChartonList = response.data;
            this.errorLogService.handleSuccess(response.message);
            this.router.navigate(['/profile']);
          } else {
            this.errorLogService.handleError(response.message)
          }
        },
        error => this.errorLogService.handleError(error)
      );
    }
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getSiteDetail() {
    this.commonService.getData('auth/setting').subscribe(
      response => {
        if (response.status) {
          var data = response.data;
          data.forEach(element => {
            if (element.Key == 'application_fee') {
              this.AdminFeePer = element.Value;
            }
          });
        }
      },
    );
  }

  onLoginClick(redirect='') {
    localStorage.setItem('redirect_after_login',redirect);
    this.LoginModelRef = this.modalService.show(this.DashboardLoginModal, { class: 'modal-md' });
  }

  onSubmitLogin(){
    this.loginForm.controls['Email'].markAsTouched()
    this.loginForm.controls['Password'].markAsTouched()
    localStorage.setItem('buy-listing-id','false')
    if (this.loginForm.valid) {
      this.userService.login(this.loginForm.value)
    }
  }

}
