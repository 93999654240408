import { Component, OnInit, ViewChild, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';
import { appURL } from "../../shared/app.constants";
import { UnMinedComponent } from '../profile/un-mined/un-mined.component';
import { BoughtComponent } from '../profile/bought/bought.component';
import { SoldComponent } from '../profile/sold/sold.component';
import { PaymentHistoryComponent } from '../profile/payment-history/payment-history.component';
import { CanceledChartonsComponent } from '../profile/canceled-chartons/canceled-chartons.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  @ViewChild('UnMinedChartonView', { read: ViewContainerRef }) UnMinedChartonView: ViewContainerRef;
  @ViewChild('BoughtChartonView', { read: ViewContainerRef }) BoughtChartonView: ViewContainerRef;
  @ViewChild('SoldChartonView', { read: ViewContainerRef }) SoldChartonView: ViewContainerRef;
  @ViewChild('PaymentHistoryView', { read: ViewContainerRef }) PaymentHistoryView: ViewContainerRef;
  @ViewChild('CanceledChartonsView', { read: ViewContainerRef }) CanceledChartonsView: ViewContainerRef;

  TotalCharton: any = 0; 
  TotalFinalCharton: any = 0; 
  UnMinedCharton: Number = 0; 
  MinedCharton: Number = 0; 
  SellCharton: Number = 0; 
  Name: string;
  CountryName: string;
  CategoryName: string;
  AboutMe: string;
  ProfileImage: string = appURL.ProfilePath + 'no_profile.png';
  constructor(
    private resolver: ComponentFactoryResolver,
    private commonService: CommonService,
    private errorLogService: ErrorLogService
  ) { }

  ngOnInit() {
    this.UnMinedChartonView.createComponent(this.resolver.resolveComponentFactory(UnMinedComponent));
    this.BoughtChartonView.createComponent(this.resolver.resolveComponentFactory(BoughtComponent));
    this.SoldChartonView.createComponent(this.resolver.resolveComponentFactory(SoldComponent));
    this.PaymentHistoryView.createComponent(this.resolver.resolveComponentFactory(PaymentHistoryComponent));
    this.CanceledChartonsView.createComponent(this.resolver.resolveComponentFactory(CanceledChartonsComponent));
    this.Name = localStorage.getItem("Name");
    this.ProfileImage = localStorage.getItem("ProfileImage");
    this.getCharton();
  }

  getCharton() {
    this.commonService.getData('auth/get_user_charton/' + localStorage.getItem('UserID')).subscribe(
      response => {
        if (response.status) {
          var UserData = response.data.UsersData
          this.Name = UserData.UserFirstName +" "+ UserData.UserLastName;
          this.ProfileImage = UserData.UserPicture;
          this.CountryName = UserData.CountryName;
          this.CategoryName = UserData.CategoryName;
          this.AboutMe = UserData.UserBiography;

          this.TotalCharton = (response.data.UnMined + response.data.Mined).toFixed(2)
          this.TotalFinalCharton = (this.TotalCharton -  response.data.Sell).toFixed(2)
          this.UnMinedCharton = response.data.UnMined
          this.MinedCharton = response.data.Mined
          this.SellCharton = response.data.Sell
          // this.Product = response.data;
          // this.BreadCrums = response.BreadCrums;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

}
