import { Component, OnInit } from '@angular/core';
import { ScrollTopService } from '../../services/scrolltop.service';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';
import { UserService } from 'src/app/services/user.service';
import { Router } from "@angular/router";

@Component({
  selector: 'app-buy-listing',
  templateUrl: './buy-listing.component.html',
  styleUrls: ['./buy-listing.component.css']
})
export class BuyListingComponent implements OnInit {
  ChartonList = []
  UnminedCountryList = ''
  CountryList = []
  CategoryList = []
  filterForm: FormGroup;
  myDate = new Date();
  constructor(
    private scrollTopService: ScrollTopService,
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    public userService: UserService,
    private router: Router,
  ) {
    this.filterForm = this.formBuilder.group({
      // ChartonType: ['', Validators.required],
      Country: [''],
      Category: this.formBuilder.array([]),
      FromPrice: [''],
      ToPrice: [''],
      FromQuantity: [''],
      ToQuantity: [''],
      SellerID: [''],
    });

  }

  ngOnInit() {

    this.commonService.postData('buy/get-unmined-country', {}).subscribe(
      response => {
        if (response.status) {
          this.UnminedCountryList = response.data.charton_data.join();
          console.log(this.UnminedCountryList,'this.UnminedCountryList')
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
    this.scrollTopService.setScrollTop();
    if (this.userService.isLoggedIn) {
      
      this.GetChartonList();
      this.GetSocialData();
    } else {
      this.router.navigate(['/'])
    }

  }
  GetUnminedCountryData(){
    
    this.commonService.postData('buy/get-unmined-country', {}).subscribe(
      response => {
        if (response.status) {
          this.UnminedCountryList = response.data.charton_data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
    
  }
  GetChartonList() {
    
    this.filterForm.controls.SellerID.setValue(localStorage.getItem('buy-listing-id'))
    this.commonService.postData('buy/get-buy-charton', this.filterForm.value).subscribe(
      response => {
        if (response.status) {
          this.ChartonList = response.data.charton_data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
    
  }
  
   GetSocialData() {
    
    this.commonService.postData('buy/get-unmined-country', {}).subscribe(
      response => {
        if (response.status) {
          this.UnminedCountryList = response.data.charton_data.join();
          this.commonService.getSocialData('common_list.php?ids='+this.UnminedCountryList).subscribe(
            response =>  {
              if (response.status) {
               
                this.CategoryList = response.data.categories;
                this.CountryList = response.data.unminers_country;
              } else {
                this.errorLogService.handleError(response.message)
              }
            },
            error => this.errorLogService.handleError(error)
          );
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
    
    
  }

  onCheckboxChange(e) {
    const Category: FormArray = this.filterForm.get('Category') as FormArray;

    if (e.target.checked) {
      Category.push(new FormControl(e.target.value));
    } else {
      let i: number = 0;
      Category.controls.forEach((item: FormControl) => {
        if (item.value == e.target.value) {
          Category.removeAt(i);
          return;
        }
        i++;
      });
    }
    // this.GetChartonList();
  }

  onSubmit() {
    this.GetChartonList();
  }

  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode == 46) {
      return true;
    } else if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
