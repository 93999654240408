import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { ErrorLogService } from '../../services/error-log.service';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.css']
})
export class TermsConditionsComponent implements OnInit {
  PageDescription: any = '';
  constructor(
    private commonService: CommonService,
    private errorLogService: ErrorLogService
  ) { }

  ngOnInit() {
      this.commonService.getData('pages/slug/term-condition').subscribe(
        response => {
          if (response.status) {
            this.PageDescription = response.data.PageDescription
          } else {
            this.errorLogService.handleError(response.message)
          }
        },
        error => this.errorLogService.handleError(error)
      );
  }


}
