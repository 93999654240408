import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-stripe-account',
  templateUrl: './stripe-account.component.html',
  styleUrls: ['./stripe-account.component.css']
})
export class StripeAccountComponent implements OnInit {

  accountForm: FormGroup;
  instruction = '*Combine the transit and institution numbers to create a full routing number <b>(transit Number = 12345, institution number = 789 ), routing number is 123456789.</b>'
  StripeAccountID = ''
  constructor(
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    private router: Router,
  ) {
    this.accountForm = this.formBuilder.group({
      FristName: ['', Validators.required],
      LastName: ['', Validators.required],
      Email: ['', Validators.required],
      Phone: ['', Validators.required],
      BirthDate: ['', Validators.required],
      Gender: ['male'],
      Address: ['', Validators.required],
      City: ['', Validators.required],
      State: ['', Validators.required],
      ZipCode: ['', Validators.required],
      Country: ['CA', Validators.required],
      AccountHolderName: ['', Validators.required],
      RoutingNumber: ['', Validators.required],
      AccountNumber: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.getUserDetails()
  }

  getUserDetails() {
    this.commonService.getData('profile/get-user-details').subscribe(
      response => {
        if (response.status) {
          var UserData = response.data
          this.StripeAccountID = response.data.StripeAccountID
          this.accountForm.controls.FristName.setValue(UserData.UserFirstName)
          this.accountForm.controls.LastName.setValue(UserData.UserLastName)
          this.accountForm.controls.Email.setValue(UserData.UserEmail)
          this.accountForm.controls.Phone.setValue(UserData.UserPhone)
          this.accountForm.controls.City.setValue(UserData.UserCurrentCity)
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
    
  }

  onSubmit() {
    this.accountForm.controls['FristName'].markAsTouched()
    this.accountForm.controls['LastName'].markAsTouched()
    this.accountForm.controls['Email'].markAsTouched()
    this.accountForm.controls['Phone'].markAsTouched()
    this.accountForm.controls['Address'].markAsTouched()
    this.accountForm.controls['BirthDate'].markAsTouched()
    this.accountForm.controls['City'].markAsTouched()
    this.accountForm.controls['State'].markAsTouched()
    this.accountForm.controls['ZipCode'].markAsTouched()
    this.accountForm.controls['Country'].markAsTouched()
    this.accountForm.controls['AccountHolderName'].markAsTouched()
    this.accountForm.controls['RoutingNumber'].markAsTouched()
    this.accountForm.controls['AccountNumber'].markAsTouched()
    if (this.accountForm.valid) {
      this.commonService.addData('profile/stripe-account', this.accountForm.value).subscribe(
        response => {
          if (response.status) {
            this.errorLogService.handleSuccess(response.message);
            this.router.navigate(['/profile']);
          } else {
            this.errorLogService.handleError(response.message)
          }
        },
        error => this.errorLogService.handleError(error)
      );
    }
  }

  country_change(country_code){
    if(country_code == 'US'){
        this.instruction = 'Use 11 digit routing number'
    } else if(country_code == 'AU'){
      this.instruction = 'Use BSB number as routing number'
    } else if(country_code == 'SG' || country_code == 'JP'){
      this.instruction = '*Combine the Bank Code and Branch Code numbers to create a full routing number (Bank Code = 12345, Branch Code = 789 ), routing number is 123456789.'
    }else if(country_code == 'IN'){
      this.instruction = 'Use IFSC Code as routing number'
    }else if(country_code == 'CA'){
      this.instruction = '*Combine the transit and institution numbers to create a full routing number (transit Number = 12345, institution number = 789 ), routing number is 123456789.'
    }else{
      this.instruction = 'Use IBAN number as routing number.'
    }
    
    
  }

}
