import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule, MatPaginatorModule, MatSortModule, MatDialogModule } from '@angular/material';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { BsDatepickerModule } from 'ngx-bootstrap';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ng6-toastr-notifications';
import { DataTablesModule } from 'angular-datatables';
import { MentionModule } from 'angular-mentions';
import { AutosizeModule } from 'ngx-autosize';
import { ValidationComponent } from '../components/validation/validation.component';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { ErrorLogService } from '../services/error-log.service';
import { ConfirmationDialogService } from '../services/confirmation-dialog.service';
import { ScrollTopService } from '../services/scrolltop.service';
import { FileDropDirective } from '../directives/file-drop.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ColorPickerModule } from 'ngx-color-picker';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    DragDropModule,
    NgxSpinnerModule,
    NgSelectModule,
    DataTablesModule,
    MentionModule,
    ToastrModule.forRoot(),
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    AutosizeModule,
    ColorPickerModule,
  ],
  declarations: [
    ValidationComponent,
    ConfirmationDialogComponent,
    FileDropDirective
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatSnackBarModule,
    MatBottomSheetModule,
    DragDropModule,
    NgxSpinnerModule,
    NgSelectModule,
    ValidationComponent,
    ConfirmationDialogComponent,
    FileDropDirective,
    DataTablesModule,
    MentionModule,
    BsDatepickerModule,
    AutosizeModule,
    MatTooltipModule,
    ColorPickerModule
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  providers: [
    DatePipe,
    ConfirmationDialogService,
    ErrorLogService,
    ScrollTopService
  ]
})
export class SharedModule { }
