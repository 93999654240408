import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sell-history',
  templateUrl: './sell-history.component.html',
  styleUrls: ['./sell-history.component.css']
})
export class SellHistoryComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
