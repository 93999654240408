import { Component, OnInit,ViewChild, TemplateRef } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';
import { appURL } from "../../shared/app.constants";
import { Router, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-un-mining',
  templateUrl: './un-mining.component.html',
  styleUrls: ['./un-mining.component.css']
})
export class UnMiningComponent implements OnInit {

  LoginModelRef: BsModalRef;
  @ViewChild('LoginUnminingModal')
  private LoginUnminingModal: TemplateRef<any>;
  loginForm: FormGroup;
  SocialSite: string = appURL.SocialSite;
  IsLogin = false;
  UnMinorsList = []
  TheUnMiners = [
    'image1081.jpg',
    'image820.jpg', 
    'image850.jpg', 
    'image1070-6.jpg', 
    'image861.jpg',
    'image872.jpg',
    'image1059-0.jpg',
    'image883.jpg', 
    'image894.jpg',
    'image1092.jpg',
    'image1059.jpg',
    'image916.jpg',
    'image927.jpg',
    'image1015-1.jpg',
    'image938.jpg',
    'image949.jpg',
    'image960.jpg',
    'image971.jpg',
    'image982.jpg',
    'image993.jpg',
    'image1004.jpg',
    'image1015.jpg',
    'image1026.jpg',
    'image1037.jpg',
    'image1048.jpg',
    'image1070.jpg',
    'image1114.jpg',
    'image1048-6.jpg',
    'image1103.jpg',
    'image1081-4.jpg',
    'image905.jpg',
    'image1037-9.jpg',
    'image839.jpg', 
    'image1026-9.jpg'
  ]
  constructor(
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    private router: Router,
    private modalService: BsModalService,
    public userService: UserService,
    public formBuilder: FormBuilder,
  ) { }

  ngOnInit() {
    this.IsLogin = this.userService.isLoggedIn
    this.loginForm = this.formBuilder.group({
      Email: ['', Validators.required],
      Password: ['', Validators.required],
    });
    setTimeout(() => {
      $(document).ready(function () {
        ($("#the_un_miners") as any).owlCarousel(
          {
            items: 5,
            lazyLoad: true,
            loop: true,
            autoplay: true,
            autoplayTimeout: 3000,
            autoplayHoverPause: true,
            dots: false,
            animateIn: 'fadeIn',
            animateOut: 'fadeOut',
            autoHeight: true,
            nav: false,
           // navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
            responsiveClass: true,
            responsive:
            {
              0: {
                items: 2
              },
              640: {
                items: 2
              },
              641: {
                items: 4
              },
              1024: {
                items: 5
              }
            }
          });

      })
    }, 500);

    this.getUnMinorsList()
  }

  getUnMinorsList() {
    
    this.commonService.getData('reports/get_un_minors').subscribe(
      response => {
        if (response.status) {
          this.UnMinorsList = response.data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }
  buy_listing_page(id,CountryID){
    console.log(id,'id')
    // localStorage.setItem('buy-listing-id', id);
    // this.router.navigate(['/buy-listing'])

    localStorage.setItem('buy-listing-id', id);
    localStorage.setItem('country-id', CountryID);
    this.router.navigate(['/'])
  }

  onLoginClick(id,CountryID) {
    localStorage.setItem('buy-listing-id', id);
    localStorage.setItem('country-id', CountryID);
    this.LoginModelRef = this.modalService.show(this.LoginUnminingModal, { class: 'modal-md' });
  }
  onSubmitLogin() {
    this.loginForm.controls['Email'].markAsTouched()
    this.loginForm.controls['Password'].markAsTouched()
    if (this.loginForm.valid) {
      this.userService.login(this.loginForm.value)
    }
  }
}
