import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonService } from '../../../services/common.service';
import { ErrorLogService } from '../../../services/error-log.service';
import { FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ConfirmationDialogService } from '../../../services/confirmation-dialog.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-bought',
  templateUrl: './bought.component.html',
  styleUrls: ['./bought.component.css']
})
export class BoughtComponent implements OnInit {
  ChartonList = []
  ChartonData = {
    'UserPicture': '',
    'CountryName': '',
    'CategoryName': '',
    'UserFirstName': '',
    'UserLastName': '',
    'OriginCountryName': '',
    'OriginCategoryName': '',
    'TotalToken': '',
    'OriginUserFirstName': '',
    'OriginUserLastName': '',
    'BasePrice': '',
    'AvailableToken': '',
    'UserBiography': '',
  }
  CurrentUserID = localStorage.getItem("UserID");
  filterForm: FormGroup;
  reportForm: FormGroup;
  ViewReportRef: BsModalRef;
  ModalTitle = 'Report Transaction';
  ModalSave = 'Report';
  @ViewChild('ReportModal')

  

  private ReportModal: TemplateRef<any>;

  constructor(
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    public formBuilder: FormBuilder,
    public confirmationDialogService: ConfirmationDialogService,
    private modalService: BsModalService,
  ) {
    this.filterForm = this.formBuilder.group({
      FromDate: [''],
      ToDate: [''],
    });
    this.reportForm = this.formBuilder.group({
      HeaderID: ['', Validators.required],
      Reason: ['', Validators.required],
    });
   }

  ngOnInit() {
    this.getChartonList()
  }

  getChartonList() {
    this.commonService.postData('profile/bought', this.filterForm.value).subscribe(
      response => {
        if (response.status) {
          this.ChartonList = response.data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

  ChangeOrder(value) {
    var array = this.ChartonList
    if(value == 1) {
      array.sort((a, b) => b.TotalToken - a.TotalToken);
    } else {
      array.sort((a, b) => a.TotalToken - b.TotalToken);
    }
  }
  
  // ReportTransaction(id) {
    
    // this.openModal(id);

    // this.confirmationDialogService.Paymentconfirm('Confirm', 'Are you sure to Report Transaction.', '', (response: boolean) => {
    //   if (response) {
    //     this.reportForm.controls.HeaderID.setValue(id)
    //     this.commonService.addData('report_transaction/add', this.reportForm.value).subscribe(
    //       response => {
    //         if (response.status) {
    //           this.errorLogService.handleSuccess(response.message);
    //           // this.router.navigate(['/thank-you/' + response.data.transaction_id]);
    //         } else {
    //           this.errorLogService.handleError(response.message)
    //         }
    //       },
    //       error => this.errorLogService.handleError(error)
    //     );
    //   }
    // });
  // }

  ReportTransaction(ID = false) {
    this.reportForm.reset();
    this.reportForm.controls.HeaderID.setValue(ID)
    if (ID) {
      this.commonService.getData('buy/get-buy-charton-details/' + ID).subscribe(
        response => {
          if (response.status) {
            this.ChartonData = response.data
          } else {
            this.errorLogService.handleError(response.message)
          }
        },
        error => this.errorLogService.handleError(error)
      );
    }
    this.ViewReportRef = this.modalService.show(this.ReportModal, { class: 'modal-lg' });
  }

  onSubmit() {
    this.reportForm.controls['Reason'].markAsTouched()
    if (this.reportForm.valid) {
      this.commonService.addData('report_transaction/add', this.reportForm.value).subscribe(
        response => {
          if (response.status) {
            this.errorLogService.handleSuccess(response.message);
            this.ViewReportRef.hide();
            var div_id = this.reportForm.controls['HeaderID'].value
            $("#report_transaction_div_"+div_id).html('<p class="text-right mb-1">Reported</p>')
          } else {
            this.errorLogService.handleError(response.message)
          }
        },
        error => this.errorLogService.handleError(error)
      );
    }
  }

}
