// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiHost: 'https://carbonface.org:4000/',
  SocialSite: 'https://carbonfacesocial.org/',
  // apiHost: 'http://localhost:3000/',
  // SocialSite: 'http://localhost/carbonface/social/',
  // StripeKey: 'pk_test_51HN1ssB9QRwRHC22LmqEyctnKFTHlPVDB9KuYchBfmI6ugpT4LQm5qKpZeuQGafWwJtur8c0acJGlluV61W9bptT00LRbD5aa3',
  StripeKey: 'pk_live_51HN1ssB9QRwRHC22W5BJMQZxNTFcRgKqZeil6w4ySd743eBUIi0nzYffHZRXCDiVmLHwHrokQ9lkcDSCT1DofyuK00J2cprTdw',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
