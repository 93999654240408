import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-cancel-chartons',
  templateUrl: './cancel-chartons.component.html',
  styleUrls: ['./cancel-chartons.component.css']
})
export class CancelChartonsComponent implements OnInit {
  ChartonList = []
  cancelForm: FormGroup;
  CurrentUserID = localStorage.getItem("UserID");
  
  constructor(
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
    private router: Router,
  ) {
    this.cancelForm = this.formBuilder.group({
      ChartonType: ['', Validators.required],
      VolumeOfCharton: ['', Validators.required],
      ChartonUser: ['', Validators.required],
    });
  }

  ngOnInit() {
  }

  onSubmit() {
    this.cancelForm.controls['ChartonType'].markAsTouched()
    this.cancelForm.controls['VolumeOfCharton'].markAsTouched()
    this.cancelForm.controls['ChartonUser'].markAsTouched()

    var CancelQuantityToken = Number(this.cancelForm.controls['VolumeOfCharton'].value) * 100
    console.log(CancelQuantityToken)
    if (this.cancelForm.valid) {
      if (0 < CancelQuantityToken) {
        this.commonService.addData('profile/cancel-chartons', this.cancelForm.value).subscribe(
          response => {
            if (response.status) {
              this.errorLogService.handleSuccess(response.message);
              this.router.navigate(['/profile']);
            } else {
              this.errorLogService.handleError(response.message)
            }
          },
          error => this.errorLogService.handleError(error)
        );
      } else {
        this.errorLogService.handleError('Please enter valid Volume Of Charton')
      }
      
    }
  }

  ChartonTypeChange(event) {
    if (event !== undefined && event.target.value) {
      this.GetChartonList()
    }
  }

  GetChartonList() {
    this.commonService.postData('sell/get-charton', this.cancelForm.value).subscribe(
      response => {
        if (response.status) {
          this.ChartonList = response.data;
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

}
