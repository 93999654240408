import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ErrorLogService } from '../../services/error-log.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  ChartonData = {}
  ID: any = 0;

  private activatedRoute: ActivatedRoute;
  constructor(
    activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private errorLogService: ErrorLogService,
  ) {
    this.activatedRoute = activatedRoute;
    this.ID = this.activatedRoute.snapshot.paramMap.get("ID");
   }

  ngOnInit() {
    this.getChartonDetails();
  }

  getChartonDetails() {
    this.commonService.getData('buy/get-buy-charton-details/' + this.ID).subscribe(
      response => {
        if (response.status) {
          this.ChartonData = response.data
        } else {
          this.errorLogService.handleError(response.message)
        }
      },
      error => this.errorLogService.handleError(error)
    );
  }

}
